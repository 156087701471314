html.dark {
    background: var(--dark-main-background);
}
.dark body{
    font-family: 'Open Sans' !important;
    color: var(--dark-primary-white-1);
    caret-color: var(--dark-primary-white-1);
}

.dark ::placeholder{
    color: var(--dark-primary-white-6);
}

.dark .no-view {
    display: none !important;
}
.dark .absolute{
    position: absolute;
}

.dark .bg-login {
    height: 100vh;
    background: var(--dark-translucent-blue-1);
    /* background-image: url('/public/img/bg-sign-in.jpeg'); */
    background-image: url('/public/img/bg-login.jpg');
    background-size: cover;
    background-position: center;
}

.dark .title-login{
    color: var(--dark-secondary-white-4);
}
.dark .button-information{
    color: var(--dark-primary-white-1);
    background: linear-gradient(0deg, var(--dark-primary-black-1), var(--dark-translucent-purple-1));
}

.dark .np-row dt{
    color: var(--dark-primary-white-6);
}

.dark .np-row dd{
    color: var(--dark-secondary-white-4);
}

.dark .login-container {
    background: var(--dark-bg-login-1);
}

.dark .section-login {
    background: var(--dark-bg-main-widget);
    box-shadow: 0px 0px 16px var(--dark-primary-gray-6);
}

.dark .subtitle-text-login {
    color : var(--dark-secondary-pearl-purple);
}

.dark .aside { 
    background: linear-gradient(195deg, var(--dark-secondary-dark-gray-3), var(--dark-secondary-dark-gray-3));
    color: var(--dark-secondary-white-pearl);  
    box-shadow: var(--dark-translucent-black-2) 0rem 0.25rem 0.375rem -0.0625rem, var(--dark-translucent-black-1) 0rem 0.125rem 0.25rem -0.0625rem;
} 

.dark .shadow-aside {
    background: var(--dark-translucent-gray-1); 
}


.dark .menu-btn-toggle {
    color: var(--dark-secondary-white-2);
    background: var(--dark-primary-black-3);
    text-shadow: 2px 1px 4px var(--dark-secondary-light-gray-4);
}

.dark .select-form{
    background-color: transparent;
}
.dark .select-form-input{
    color: var(--dark-primary-white-1);
}

.dark .select-form-select{
    color: var(--dark-secondary-white-1);
}
/*Color letra de selects*/
.dark .select-form-select .MuiInputBase-input { 
    color: var(--dark-primary-white-1)
}

/* .dark .MuiFormLabel-colorPrimary>label{
    color: var(--dark-primary-white-1);
} */

.dark .select-form-error{
    color: var(--dark-secondary-alert-red);
}


.dark .loader {
    background: var(--dark-bg-loder-1);
}

.dark .bold-container-content {
    background: var(--dark-bg-main-widget);
    color: var(--dark-primary-white-6);
}

.dark .bold-container-content2 {
    color: var(--dark-primary-white-6);
}

.dark .bold-container-content3 {
    color: var(--dark-primary-white-6);
    font-size: 28px;
    font-weight: 600;
    
}

.dark .arrow-back-icon{
    color: var(--dark-primary-white-6);
}

.dark span.arrow-back-icon:hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 70%;
    height: 150%;
    background: var(--dark-translucent-gray-3);
    border-radius: 100%;
    transform: translate(-50%, -50%);
}

.dark .arrow-back-icon2 span:hover::before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    background: var(--dark-translucent-gray-3);
    border-radius: 100%;
    transform: translate(-20%, -20%);
   
}

.dark .container-result{
    background-color: var(--dark-background-white);
}

.dark .check{
    background-color: var(--dark-primary-black-4);
    border-radius: 100%;
}

.dark .download-button{
    color: var(--dark-primary-red-2);
}

.dark .result-transaction{
    box-shadow: 2px 2px 12px 0px var(--dark-secondary-shadow-gray);
}

.dark .card-content {
    border: 1px solid var(--dark-secondary-white-4);
}

.dark .card-content-modal {
    border: 1px solid var(--dark-translucent-gray-2);
    background-color: var(--dark-primary-white-1);
}

.dark .card-content-modal button {
    border: 1px solid var(--dark-translucent-gray-2);
    background-color: var(--dark-primary-white-1);
}

.dark .logo {
    border-bottom: 1px solid var(--dark-translucent-black-3);
}

.dark .modal-backdrop {
    background-color: var(--dark-primary-green-1);
  }

/* ASIDE */
.dark li.menu-item>a {
    color: var(--dark-secondary-white-2);
}

.dark li.menu-item>a:hover {
    background: var(--dark-primary-gray-7);
}

.dark li.menu-item span.material-symbols-outlined {
    color: var(--dark-primary-white-5);
}

.dark #my-first-step {
    color: var(--dark-primary-white-5);
}

.dark .user-profile {
    color: var(--dark-primary-white-1);
}


/* ASIDE ACCORDION */
.dark li.menu-item.collapsable>a.collapsed:after {
    border-bottom: 2px solid var(--dark-secondary-white-4);
    border-right: 2px solid var(--dark-secondary-white-4);
}

.dark li.menu-item.collapsable>a:after {
    border-bottom: 2px solid var(--dark-secondary-white-4);
    border-right: 2px solid var(--dark-secondary-white-4);
}


/* MENU */


/* input search */
.dark .input-icon.input-search .clear{
    color: var(--dark-secondary-candy-red);
}

.dark .input-icon input {
    border: 1px solid var(--dark-secondary-dark-gray-2);
    background-color: var(--dark-secondary-dark-gray-2);
    color: var(--dark-primary-white-6);
}
.dark .input-icon span {
    position: absolute;
    top: 6px;
    right: 20px;
    color: var(--dark-secondary-light-gray-4);
    text-shadow: 2px 1px 2px transparent;
}

/* FOOTER */
.dark .footer-branch {
    font-size: 15px;
    margin: 20px 15px 0px;
    color: var(--dark-secondary-purple-night);
}

.dark ul.important-links {
    display: flex;
    list-style: none;
    margin-bottom: 5px;
}

.dark ul.important-links li {
    padding: 0px 12px;
}

.dark ul.important-links a {
    text-decoration: none;
}

.dark .company-branch-link {
    font-weight: 700;
    text-decoration: none;
    color: var(--dark-primary-white-6);
}

.dark .modal-sub-location {
    background-color: var(--dark-bg-main-widget);
    border: 1px solid var(--dark-translucent-blue-2);

}

.dark .plan-card{
    /* background-color: #var(--dark-primary-white-1); */
    /* color:var(--dark-primary-white-1); */
    border-radius: 20px;
    border: 1px solid var(--dark-color-blue-2);
    /* box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem, rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem; */
    box-shadow: 0px 0px 15px var(--dark-shadow-plan);

}

/* TABLE */

.dark .column-dt dt {
    color:var(--dark-secondary-blue-steel);
}
.dark .column-dd dt {
    color:var(--dark-secondary-blue-steel);
}

.dark .bold-header-table h3 {
    color: var(--dark-primary-white-6);
}

.dark .action-buttons{
    display: flex;
    margin-right: 10px;
}
.dark button.btn-table-actions {
    background: transparent;
    border: 0px;
    color: var(--dark-primary-white-6);
    text-shadow: 2px 1px 2px transparent;
    cursor: pointer;
}

.dark .btn-table-actions-massives.inactive span {
    color: white;
}
.dark button.btn-table-actions-massives.inactive {
    background-color: #C8C10D;
    color: var(--dark-primary-white-1);
}


.dark button.btn-table-actions-massives.active {
    background-color: #0DC858;
    color: var(--dark-primary-white-1);
}

.dark button.btn-table-actions-massives.edit {
    background-color: #0DC8B2;
    color: var(--dark-primary-white-1);
}

.dark button.btn-table-actions-massives.delete {
    background-color: #EE0A0A;
    color: var(--dark-primary-white-1);
}

.dark button.btn-table-actions-massives.cancel {
    background-color: #EE0A0A;
    color: var(--dark-primary-white-1);
}


.dark .btn-table-actions span {
    display: inline-block;
    position: relative;
    cursor: pointer;
}

.dark .btn-table-actions span:hover::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%;
    height: 150%;
    background: rgba(118, 118, 118, 0.209);
    border-radius: 100%;
    transform: translate(-50%, -50%);
}

.dark .table-content {
    background-color: var(--dark-secondary-dark-gray-3);
}


.dark button.btn-table-add {
    background: transparent;
    border: 2px solid var(--dark-primary-white-6);
    color: var(--dark-primary-white-6);
    text-shadow: 2px 1px 2px var(--dark-color-input-search);
}

.dark button.btn-table-actions span {
    font-size: 20px;
}

.dark .material-symbols-outlined.active{
    color: var(--dark-secondary-fluorescent-green) !important;
}
.dark .material-symbols-outlined.inactive{
    color: var(--dark-secondary-light-yellow) !important;
}

.dark .card-numbers {
    color: var(--dark-primary-white-6);
}

.dark dt {
    font-weight: 600;
    color: var(--dark-primary-white-6);
    font-size: 15px;
}
.dark dd {
    color: var(--dark-secondary-white-2);
}
.dark .flex-column-container {
    flex-wrap: wrap;
}

/* BUTTOMS */

.dark .btn-action-dash span {
    color: var(--dark-primary-white-5);
}
.dark .bold-btn {
    background: transparent;
}

.dark button.bold-btn-dark {
    color: var(--dark-primary-white-1);
    background: var(--dark-secondary-coral-red);
    border-color: var(--dark-secondary-coral-red);
}
.dark button.pagination-button {
    background-color: var(--dark-bg-main-widget);
    border: 1px solid var(--dark-primary-white-1);
    color: var(--dark-primary-white-2);
}
.dark button.cancel-subscription-button {
    border: none;
    background-color: transparent;
    color:var(--dark-secondary-white-1);
}
.dark button.btn-gray {
    color: var(--dark-primary-white-1);
    background: linear-gradient(0deg, var(--dark-primary-gray-5), var(--dark-primary-gray-1));
    border-radius: 8px;
}

.dark button.btn-gray-light {
    color: var(--dark-primary-white-1);
    background: linear-gradient(0deg, var(--dark-bg-button-3), var(--dark-secondary-light-gray-1));
    border-radius: 8px;
}

.dark button.bold-btn-dark-big {
    color: var(--dark-primary-white-1);
    background: linear-gradient(0deg, var(--dark-translucent-blue-1), var(--dark-translucent-purple-1));
    border-radius: 8px;
    width: 75%;
    border-color: var(--dark-translucent-blue-1);
}

.dark button.bold-btn-light {
    color: var(--dark-primary-white-1);
    background: var(--dark-primary-purple-2);
    border-radius: 8px;
}
.dark .dropdown-toggle {
    color: var(--dark-primary-white-1);
}

.dark .dropdown-menu {
    background-color: var(--dark-primary-black-5);
    border: 0.5px solid var(--dark-secondary-light-gray-3);
  }

.dark .dropdown-item {
    color: var(--dark-primary-white-1);
    border-bottom-color: var(--dark-secondary-steel-blue);
}


.dark .dropdown-item:hover {
    background-color: var(--dark-primary-gray-5);
}

.dark .dropdown-item:focus {
    background-color: var(--dark-primary-gray-5);
}

.dark button.bold-btn-dark:disabled {
    color: var(--dark-secondary-light-gray-3);
    background: var(--dark-translucent-red-1);
    border-color: var(--dark-translucent-red-2);
}

.dark button.bold-btn-dark.back {
    color: var(--dark-primary-white-1);
    background: var(--dark-primary-purple-2);
    border-radius: 8px;
    border-color: var(--dark-primary-purple-2);
}
.dark .red-dot {
    background-color: var(--dark-primary-red-3);
}
.dark .green-dot {
    background-color: var(--dark-primary-green-3);
}
.dark .yellow-dot {
    background-color: var(--dark-secondary-fluorescent-yellow);
}


/*no sé*/
.dark .section-bold-upload-files {
    background: var(--dark-secondary-dark-gray-1);
    color: var(--dark-primary-white-1);
    border-color: var(--dark-primary-white-5);
}
.dark .title-dessert-config{
    color: var(--dark-primary-white-1);
}

.dark #product_view {
    color: var(--dark-primary-white-1);
}

.dark .eye-icon {
    color: var(--dark-primary-white-1);
}

.dark .bold-container-content .material-symbols-outlined{
    color: var(--dark-primary-white-4);
}

.dark .material-symbols-outlined{
    color: var(--dark-secondary-white-3) ;
}

.dark .circle-name .material-symbols-outlined{
    color: var(--dark-primary-purple-2) ;
}

.dark .bold-infinite-field {
    background-color: transparent;
    color: var(--dark-primary-white-2);
}

.dark .text-details{
    color: var(--dark-primary-white-6);
}

.dark .title-position {
    color: var(--dark-primary-white-5);
}

.dark .bold-infinite-title-section h3 {
    color: var(--dark-primary-white-1);
}

.dark button.bold-infinite-option {
    color: var(--dark-primary-white-1);
}

.dark label.bold-infinite-label{
    color: var(--dark-primary-white-1);
}

.dark .background-register{
    background: var(--dark-bg-main-widget);
}

.dark .error-404{
    background-color: var(--dark-main-background);
    color: var(--dark-primary-white-6);
}

.dark .bold-selector-option {
    color: var(--dark-primary-white-1);
    background-color: var(--dark-secondary-white-1);
    border-color: var(--dark-primary-black-1);
    z-index: 2;
}

.dark .bold-selector-option:hover {
    background-color: var(--dark-primary-gray-7);
}
/* .dark .no-data-display .rdt_TableCell {
    background-color: black;
    color: var(--dark-primary-white-1);
} */
 


/* .dark .css-1nrlq1o-MuiFormControl-root {
    background-color: transparent;
} */


/* Forms */

.dark .bold-container-content > .row {
    color: var(--dark-primary-white-6);
}

.dark .section-forms {
    background: var(--dark-secondary-dark-gray-3);
    /* box-shadow: var(--dark-shadow-section-1) 0rem 0.25rem 0.375rem -0.0625rem, var(--dark-translucent-black-1) 0rem 0.125rem 0.25rem -0.0625rem; */
}

.dark .title-section-forms {
    color: var(--dark-primary-white-4); 
}

.dark .title-section-forms > h3{
    color: var(--dark-primary-white-4);
}

.dark .title-section-forms p{
    color: var(--dark-secondary-white-1);
    font-size: 13px;
}

.dark .bold-container-content .subtitle{
    color: var(--dark-primary-white-6);
}
.dark .title-section-products-forms {
    width: 90%;
    display: block;
    text-align: left;
    color: var(--dark-primary-white-6);
    font-size: 28px;
    font-weight: 600;
    margin-left: 30px;
}

.dark .title-section-products-forms > h3{
    color: var(--dark-primary-white-6);
}

.dark .title-section-products-forms p{
    color: var(--dark-secondary-white-5);
}

.dark .title-section-sub {
    color: var(--dark-primary-white-6);
}
.dark .title-section {
    color: var(--dark-secondary-blue-steel);
}

.dark .form-title {
    color: var(--dark-secondary-white-5);
}
.dark .form-title-sub {
    color: var(--dark-secondary-blue-steel);
}
.dark .description-input {
    color: var(--dark-primary-white-2);
}
.dark span.divisor {
    background: var(--dark-secondary-light-gray-3);
}

.dark .orders-to-close {
    height: 80%;
    overflow: overlay;
}

.dark .section-bottom-card-close {
    border-top: 1px solid var(--dark-secondary-grey-silver);
}

.dark .green-text {
    color: var(--dark-primary-green-2);
}

.dark .total-text {
    color: var(--dark-secondary-gray-blue);
}

.dark .text-order {
    color: var(--dark-secondary-light-gray-3);
}

.dark .color-white-txt {
    color: var(--dark-primary-white-5);
}

/* checkbox */
.dark label.MuiFormControlLabel-root span:nth-child(2) {
    color: var(--dark-primary-white-6);
}

/* Text */
.dark .text-green {
    color: var(--dark-primary-green-4);
}

.dark .text-orange {
    color: var(--dark-color-text-orange-1);
}

.dark .text-link {
    color: var(--dark-primary-red-2);
    text-decoration: none;
    cursor: pointer;
}

.dark .text-linkSub {
    text-decoration: underline;
    color: var(--dark-primary-red-2);
}

.dark .text-red {
    color: var(--dark-primary-red-2);
}

.dark .text-gray {
    color: var(--dark-secondary-light-gray-1) !important;
}

/* Widgets */
.dark .widget {
    background: var(--dark-bg-main-widget);
}

.dark .widget hr {
    clear: both;
    color: var(--dark-secondary-light-gray-1);
}

.dark .icon-widget-1 {
    background: linear-gradient(0deg, var(--dark-secondary-fluorescent-blue), var(--dark-secondary-fluorescent-blue));
}

.dark .icon-widget-2 {
    background: linear-gradient(0deg, var(--dark-secondary-fluorescent-green), var(--dark-secondary-fluorescent-green));
}

.dark .icon-widget-3 {
    background: linear-gradient(0deg, var(--dark-secondary-light-yellow), var(--dark-secondary-light-yellow));
}

.dark .icon-widget-4 {
    background: linear-gradient(0deg, var(--dark-primary-fucsia-1), var(--dark-primary-fucsia-1));
}

.dark .icon-widget span {
    color: var(--dark-primary-white-1);
}

.dark .title-widget {
    color: var(--dark-primary-white-1);
}

.dark .title-widget-complement {
    color: var(--dark-primary-white-1);
}


/* MUI library */
/* borde inferior inputs */
/* 

/* Color de options del select de infiniteAddItems */
.dark .bold-infinite-select-empty-option{
    color: var(--dark-primary-white-1);
    background-color: var(--dark-secondary-dark-gray-1);
}
.dark .bold-infinite-select-option{
    color: var(--dark-primary-white-1);
    background-color: var(--dark-secondary-dark-gray-1);
}
.dark .bold-infinite-select-option:checked{
    background-color: var(--dark-secondary-dark-gray-4);
}

/* Estilos para la barra de desplazamiento en navegadores basados en WebKit */
.dark select::-webkit-scrollbar {
    width: 12px; /* Ancho de la scrollbar */
  }

.dark select::-webkit-scrollbar-track {
    background: var(--dark-secondary-dark-gray-1); /* Color de fondo de la scrollbar */
  }

.dark select::-webkit-scrollbar-thumb:hover {
    background: var(--dark-primary-gray-2); /* Color de la barra de desplazamiento al pasar el ratón */
  }

/* Estilos del step que no se han realizado */
.dark .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root {
    fill: var(--dark-primary-gray-4);
}

.dark .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active{
    fill: #36366e
     ;
}

.dark .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed{
    fill: #484894;
}

/* color del span debajo de cada step  */
.dark .css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel  {
    color: var(--dark-primary-white-1);
}

.dark .css-2fdkz6.MuiStepLabel-alternativeLabel {
    font-size: 0.875rem;
    margin-top: 16px;
    font-weight: 500;
}
.css-2fdkz6.MuiStepLabel-alternativeLabel {
    font-size: 0.875rem;
    margin-top: 16px;
    font-weight: 500;
}
.dark .css-2fdkz6.Mui-active{ 
    color: var(--dark-primary-white-1);
}

.css-2fdkz6.Mui-active{ 
    color: rgba(0, 0, 0, 0.87);
}

.dark .css-2fdkz6.MuiStepLabel-alternativeLabel{
    margin-top: 16px;
}

.css-2fdkz6.MuiStepLabel-alternativeLabel{
    margin-top: 16px;
}

.css-1vyamtt-MuiStepLabel-labelContainer.MuiStepLabel-alternativeLabel{
    text-align: center;
    margin-top: 16px;
}

/* Estilos closeOut configuration */
.dark .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: var(--dark-primary-white-5);
}

/* color del borde en escritorio y pantallas pequeñas */
.dark .css-1varjmz-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-primary-white-6);
}

.dark .css-17712so-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-primary-red-2);
}

.dark .css-9425fu-MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-primary-white-6);
}

.dark .css-1np443e-MuiInputBase-root-MuiInput-root {
    color: var(--dark-primary-white-2)
}

/* Estilos datatables modo oscuro para complementar theme de dataTable */
/* .dark .rdt_Table tr{

background-color: var( --dark-primary-gray-1);
color: var(--dark-primary-white-1);
}


.dark .rdt_TableHead {
background-color: var(--dark-bg-main-widget);
color: var(--dark-primary-white-6);
}


.dark .rdt_TableHeadRow {
background-color: var(--dark-primary-white-6);
}

.dark .rdt_TableCellRow {
border-color: transparent;
border-bottom-color: var(--dark-primary-white-6);

}

.dark .rdt_TableCell {
background-color: var(--dark-bg-main-widget);
color: var(--dark-primary-white-6);
}

.dark .rdt_Pagination {
background-color: transparent;
color: var(--dark-primary-white-6);
}

.dark .rdt_PaginationPrev,
.dark .rdt_PaginationNext,
.dark .rdt_PaginationPage {
background-color: var(--dark-primary-white-6) !important;
color: var(--dark-primary-white-1);
}

.dark .rdt_PaginationPage.active {
background-color: var(--dark-primary-white-5); 
color: var(--dark-primary-white-1); 
border-color: var(--dark-primary-blue-1-1);
} */

.dark .rdt_TableCol {
    background-color: var(--dark-bg-main-widget);
    color: var(--dark-primary-white-6);
    }
    
    .dark .rdt_TableCell {
    background-color: var(--dark-bg-main-widget);
    color: var(--dark-primary-white-6);
    }

    .dark .bzRnkJ {
        background-color: var(--dark-bg-main-widget);
        color: var(--dark-primary-white-6); 
    }

    .dark .cWOgbd {
        background-color: var(--dark-bg-main-widget);
        color: var(--dark-primary-white-6); 
    }
    
    /* .dark #pagination-previous-page,
    .dark #pagination-next-page,
    .dark #pagination-first-page,
    .dark #pagination-last-page 
    {
        background-color: white !important;
    } */
    
    /* desplegable de tablas */
    .dark .bOmZm option {
        color: var(--dark-primary-white-1);
        background-color: var(--dark-primary-black-5);
    }
    
    .dark .ezkotw {
        background-color: var(--dark-bg-main-widget) !important;
        color: var(--dark-primary-white-1) !important;
    }

    .dark .jaZsat {
        background-color: var(--dark-bg-main-widget);
        color: var(--dark-primary-white-1);
    }

    .dark .jaZsat > option {
        background-color: var(--dark-bg-main-widget);
        color: var(--dark-primary-white-1);
    }

    /* .kDEcCH {
        background-color: transparent !important;
    } */
    
    /* Estilos para los modals */
    
    .dark .swal2-popup {
        background-color: var(--dark-secondary-dark-gray-6);
        color: var(--dark-primary-white-1);
    }
    
    .dark .custom-confirm-button-class {
        background-color: var(--dark-primary-purple-1) !important; /* Fondo del botón de confirmación */
        border-color:  transparent;
        color: var(--dark-primary-white-1); /* Color del texto del botón */
    }
    
    .dark .swal2-styled.swal2-confirm {
        background-color: var(--dark-primary-purple-1);
        color: var(--dark-primary-white-1);
    }
    
    .dark .swal2-styled.swal2-cancel {
        background-color: var(--dark-primary-gray-4);
    }
    
    .dark div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
        background-color: var(--dark-color-icon-error) ; /*modifica la equis del icono error*/
    }
    
    .dark .swal2-icon.swal2-error {
        border-color: var(--dark-color-icon-error) ;
    }
    
    .dark .swal2-icon.swal2-info {
        border-color: var(--dark-secondary-fluorescent-blue);
        color: var(--dark-secondary-fluorescent-blue);
    }
    
    .dark .swal2-icon.swal2-warning {
        border-color: var(--dark-secondary-light-yellow);
        color: var(--dark-secondary-light-yellow);
    }
    
    .dark .swal2-icon.swal2-success {
        border-color: var(--dark-color-icon-success);
        color: var(--dark-color-icon-success);
    }
    
    .dark .swal2-icon.swal2-success .swal2-success-ring {
        border-color: var(--dark-color-icon-success);
    }
    
    .dark .swal2-icon.swal2-success [class^=swal2-success-line] {
        background-color: var(--dark-color-icon-success);
    }
    
    .dark .swal2-icon.swal2-success [class^=swal2-success-line] {
        background-color: var(--dark-color-icon-success);
    }
    
    
    .dark input:-webkit-autofill,
    .dark textarea:-webkit-autofill{
        -webkit-box-shadow: 0 0 0px 1000px var(--dark-primary-blue-2) inset;
        box-shadow: 0 0 0px 1000px var(--dark-bg-main-widget) inset;
        -webkit-text-fill-color: var(--dark-primary-white-1); /* Cambia el color del texto */
    }
    
    /* Estilo para el fondo del autocompletar cuando el campo está activo */
    .dark input:-webkit-autofill:focus,
    .dark textarea:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px var(--dark-secondary-focus-red) inset;
        box-shadow: 0 0 0px 1000px var(--dark-bg-main-widget) inset;
        -webkit-text-fill-color: var(--dark-primary-white-1); /* Cambia el color del texto */
    }
    
    .dark .MuiInput-adornment-end {
        background-color: black;
    }
    
    .dark input[type="time" i] {
        background-color: var(--dark-bg-main-widget);
        color: var(--dark-primary-white-1);
        
    }
    
    /* botones de incremento y decremento del navegador */
    
    /* .dark input[type="number"]::-webkit-inner-spin-button:hover,
    .dark input[type="number"]::-webkit-outer-spin-button:hover {
        -webkit-appearance: none;
        background: gray; 
        color: rgb(112, 0, 0);
        width: 15px;
        height: 30px;
        border-radius: 2px;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px rgb(255, 255, 255) !important;
    }
    
    .dark input[type="number"]::-webkit-outer-spin-button::after {
      content: '+';
      color: rgb(20, 20, 20);
      display: block;
      font-size: 14px;
    }
    
    .dark input[type="number"]::-webkit-inner-spin-button::after {
        color: rgb(0, 0, 0); 
        display: block;
        font-size: 14px;
        border: 1px solid black;
    } */
    
    /*Botones de aumentar y decrementar en inputs de tipo número*/
    
    .dark input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    /* .dark input[type=number] { -moz-appearance:textfield; } */
    
    
    /* .dark .MuiInput-root.MuiInput-increment {
        background-color: blue;
    } */
    
    .dark .percentage-icon{
        color: var(--dark-primary-white-1);
    }
    
    .dark .select-location-text {
        color: var(--dark-primary-white-1);
    }
    
    /* detalles de ordenes */
    .dark .detail-order{
        background-color: var(--dark-bg-main-widget);
        color: var(--dark-primary-white-6);
    }
    
    .dark .css-83ijpv-MuiTypography-root {
        color: var(--dark-primary-white-6);
    }
    
    .dark .detail-order-divider{
        border-color: var(--dark-secondary-steel-blue);
    }
    
/*USERS FORM*/
.dark .circle-name {
    background: var(--dark-secondary-dark-gray-5);
    color: var(--dark-primary-white-1);
}

.dark .bold-button-modal {
    background: var(--dark-secondary-white-1);
    color: var(--dark-secondary-dark-gray-5);
}
.dark a.bold-button-modal:hover {
    color: var(--dark-primary-gray-1);
}

.dark .form-username label {
    color: var(--dark-primary-white-5);
}

.dark .breadcrumb-item a {
    color: var(--dark-color-breadcrum-1); /* Cambiar el color del texto a tu preferencia */
}

.dark .breadcrumb-item a:hover {
    color: var(--dark-color-breadcrum-2); /* Cambiar el color del texto al pasar el mouse por encima */
}

.dark .no-imformation-opacity{
    color: var(--dark-translucent-lavender-1);
}

/* infinite add items */
.dark .register-subtitle-1 {
    color: var(--dark-secondary-white-pearl);
    font-weight: bold;
}

.dark .register-subtitle-2 {
    color: var(--dark-primary-white-6);
    font-size: 14px;
}

.dark .simple-text {
    color: var(--dark-primary-white-3);
}

.dark .no-data-display-text{
    color: var(--dark-primary-white-1);
}

/* detalles transacción cliente */
.dark .table th {
    color: var(--dark-primary-white-6);
}

.dark .table td {
    color: var(--dark-primary-white-6);
}


.dark .custom-text-field input{
    background-color: var(--dark-bg-main-widget);
    color: var(--dark-primary-white-2);
}

.dark a {
    color: #4aa7ff;
}

.dark .files-text {
    color: var(--dark-primary-white-5);
}

.dark .inner-upload span.material-symbols-outlined {
    box-shadow: 0px 0px 13px var(--dark-translucent-white-2);
}

.dark .registerContainerForm {
    background-color: var(--dark-bg-main-widget);
}

/* Botón cierre de caja */
.dark .container-close-cashier--button {
    color: var(--dark-primary-white-4);
}

.dark .dark-mode-toggle span,
.dark .dark-mode-toggle p {
    color: var(--dark-primary-white-3);
}


/* Responsive */
@media (max-width: 768px) {

    .dark .registerForm {
        background-color: var(--dark-bg-main-widget);
    }
    .registerContainerForm .dark-mode-toggle span, 
    .dark .dark-mode-toggle p {
        color: var(--dark-color-breadcrum-2);
    }
}

@media (min-width: 769px) {
    .dark .registerContainerForm {
        background-color: var(--dark-bg-main-widget);
    }
}
   

.dark .tittle-position{
    color: var(--dark-primary-white-5);
}

.dark .text-details {
    color: var(--dark-primary-white-6);
}

.dark .underline {
    color: #bbb2b2e7; /* Puedes cambiar el color según tus preferencias */
}

/* Estilos libreria Mui material  */
.dark .MuiFormLabel-root{
    color:var(--dark-primary-white-1) !important;
}

.dark .MuiInputLabel-root{
    color:var(--dark-primary-white-1);
}


.dark .MuiInputBase-input{
    color:var(--dark-primary-white-1);
}

.dark .MuiInputBase-root{
    color: var(--dark-primary-white-1);
    caret-color: var(--dark-primary-white-1);
}

.dark .MuiInput {
    color:var(--dark-primary-white-1)
}

.dark .MuiPaper-root {
    background-color: var(--dark-secondary-dark-gray-6);
    color: var(--dark-primary-white-1);
}

.dark .MuiPaper-elevation{
    background-color: var(--dark-secondary-dark-gray-6);
}

.dark .Mui-selected {
    background-color: var(--dark-translucent-light-blue) !important;
    color: var(--dark-primary-white-1);
}

.dark .MuiFormControl-root {
    border-color: var(--dark-primary-white-1) !important;
}

.dark .MuiChip-root {
    background-color: var(--dark-secondary-dark-gray-6);
    color: var(--dark-primary-white-1);
}

.dark .MuiCheckbox-root{
    color: var(--dark-primary-white-1);
}

.dark .Mui-checked{
    color: var(--dark-primary-white-1);
}

.dark .MuiOutlinedInput-notchedOutline {
    border-color: var(--dark-primary-white-1);
}


.dark .MuiPickersArrowSwitcher-button.Mui-disabled{
    color:var(--dark-primary-red-1);
}

.dark .MuiPickersYear-yearButton.Mui-disabled{
    color: var(--dark-secondary-pearl-purple);
}

.dark .MuiDayCalendar-header {
    color: var(--dark-primary-var(--dark-primary-white-1)-1);
}

.dark .MuiTypography-root{
    color: var(--dark-primary-white-1);
}

.dark .MuiPickersDay-root{
    color: var(--dark-primary-white-2);
}

.dark .MuiPickersDay-root:hover{
    background-color: var(--dark-translucent-purple-2);
}

.dark .MuiDayCalendar-weekContainer .Mui-disabled{
    color: var(--dark-primary-white-2);
}

.dark .MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.Mui-disabled{
    color: var(--dark-secondary-light-gray-4);
}

.dark .MuiButtonBase-root.Mui-disabled.MuiIconButton-root.Mui-disabled .MuiSvgIcon-root{
    color: var(--dark-secondary-light-gray-4);
}

.dark .MuiSvgIcon-root{
    color: var(--dark-primary-white-1);
}

.dark .MuiSelect-iconOutline{
    color: var(--dark-primary-white-1);
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconStandard {
    font-size: 1.5rem;
}

.dark .MuiDateCalendar-root {
    color: var(--dark-primary-white-1);
}


.dark .MuiAlert-root{
    color: var(--dark-secondary-alert-red);
}

.dark .MuiAlert-root .MuiSvgIcon-root{
    color: var(--dark-secondary-alert-red);
}

.dark .Mui-checked.MuiButtonBase-root.MuiCheckbox-colorPrimary.MuiCheckbox-root > .MuiSvgIcon-root{
    color: var(--dark-primary-white-3);
}

.dark .container-table .Mui-checked.MuiButtonBase-root.MuiCheckbox-colorPrimary > .MuiSvgIcon-root{
    color: var(--dark-secondary-fluorescent-blue);
    font-size: 1.2rem;
}

.dark .MuiAutocomplete-noOptions {
    color: var(--dark-translucent-white-1);
}

.dark .MuiAutocomplete-option.Mui-focused{
    background-color: var(--dark-bg-hover-select) !important;
}

.dark .MuiAutocomplete-option[aria-selected="true"] {
    background-color: var(--dark-translucent-light-blue) !important;
}

/* Alerta de closeout  */
.dark .alert-warning{
    background-color: var(--dark-secondary-soft-yellow);
}

.dark .alert-warning .material-symbols-outlined {
    color: var(--dark-secondary-dark-gray-1);
}

/* Chips de detalles de orden  */

.dark .extras-chip{
    color: var(--dark-primary-fucsia-1);
    border: 1px solid var(--dark-primary-fucsia-1) ; 
}

.dark .removed-ingredient-chip{
    color: var(--dark-primary-fluorescent-red);
    border: 1px solid var(--dark-primary-fluorescent-red); 
}

/* Bordes inputs */

.dark .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-error {
    border-bottom-color: var(--dark-color-error) !important;
}

.dark .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-error.Mui-focused {
    border-bottom-color: var(--dark-color-error) !important;
}

.dark .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-focused.MuiInputBase-formControl fieldset{
    border: 1px solid #1976d2 !important;
}

.dark .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error fieldset{
    border-color: var(--dark-color-error)!important;
}

.dark .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.Mui-error.Mui-focused fieldset {
    border-color: var(--dark-color-error)!important;
}

.dark .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover fieldset{
    border: 1px solid var(--dark-primary-white-1) ;
} 

.dark .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-disabled input[type="text"][disabled] {
    -webkit-text-fill-color: var(--dark-secondary-light-gray-3);
}

.dark .MuiFormControl-root.MuiTextField-root .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-disabled:hover{
    border-bottom: unset;
}

.dark .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconStandard.Mui-disabled{
    color: var(--dark-secondary-light-gray-3);
}

.dark .MuiSelect-select.MuiSelect-standard.Mui-disabled.MuiInputBase-input.MuiInput-input.Mui-disabled.MuiInputBase-inputSizeSmall{
    -webkit-text-fill-color: var(--dark-secondary-light-gray-3);
}

.dark .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-disabled.MuiInputBase-formControl:hover{
    border-bottom: unset;
}

.dark .MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected.MuiMenuItem-root.MuiMenuItem-gutters.Mui-selected{
    color: var(--dark-primary-white-2);
}

.css-jcincl:hover:not(.Mui-disabled, .Mui-error)::before{
    border-bottom: 1px solid var(--dark-color-border-inputs);
}

.dark .MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-colorPrimary.Mui-focused {
    border-bottom: 0px solid #1976d2 !important;
}

.Mui-focused:after{
    border-bottom: 1px solid #1976d2 !important;
}

.dark .MuiInput-root:before {
    border-bottom: 1px solid var(--dark-color-border-inputs) !important;
}

.dark .Mui-error:before{
    border-bottom: 1px solid #8c1a1a !important;
}