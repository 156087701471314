
/* Form products */
.dark .card-option {
    display: block;
    text-align: center;
    border: 1px solid var(--dark-border-white-1);
    border-radius: 8px;
    overflow: hidden;
    margin: 10px 5px;
    box-shadow: 2px 2px 12px 0px var(--dark-translucent-gray-1);
}
.dark .card-option.selected {
    border: 2px solid var(--dark-primary-blue-3);
    transform: scale(1.1);
}

.dark .card-option-img-content {
    display: flex;
    padding: 0px;
    overflow: hidden;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}
.dark img.card-option-img {
    display: block;
    width: 90%;
}
.dark .card-option-content {
    display: flex;
    min-height: 45px;
    padding: 5px;
    align-items: center;
    justify-content: center;
    background: var(--dark-secondary-dark-gray-6);
    font-weight: 600;
    word-break: break-word;
}

/* .dark .card-option-title {
    color: red;
} */

/* dropdown */
.dark .dropdown-item{
    margin: 3px;
    border-bottom: 1px solid var(--dark-border-white-2);
}
/* main header */
.dark .btn-action-dash span.material-symbols-outlined {
    position: relative;
    top: 3px;
    font-size: 22px;
}
.dark .actions-dashboard {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.dark span.location-label {
    font-size: 13px;
    color: var(--dark-primary-white-6);
    line-height: 13px;
}

/* table */
.dark .bold-table-note {
    max-width: 920px;
    padding: 0px 20px;
    margin: 10px 0px;
}

/* btn table actions*/
.dark .btn-table-actions.wx2 {
    width: 52px;
    position: relative;
}
.dark span.xsmall {
    font-size: 8px !important;
    position: absolute;
    top: 20px;
    left: 0px;
    right: 0;
}


/* card products selected in form */
.dark .card-option-selected {
    border: 2px solid var(--dark-border-red-1);
}

.dark .btn-linked:visited {
    color: '#997404'
}

.dark .small-text{
    font-size: 11px !important;
}

html.dark {
    ::-webkit-scrollbar {
        width: 10px;
      }
    
    ::-webkit-scrollbar-track {
        background: transparent;
      }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--dark-secondary-light-gray-1);
        border-radius: 17px;
        border: 0px solid var(--dark-primary-white-1);
    };
  }

.dark .aside {
    ::-webkit-scrollbar {
        width: 5px;
      }
    
    ::-webkit-scrollbar-track {
        background: transparent;
      }
    
    ::-webkit-scrollbar-thumb {
        background-color: var(--dark-bg-gray-2);
        border-radius: 17px;
        border: 0px solid var(--dark-primary-white-1);
    };
  }
  @-moz-document url-prefix() {
    html.dark {
        scrollbar-width: thin;
        scrollbar-color: var(--dark-secondary-light-gray-1) transparent;
      }
  }
  .dark .MuiInputBase-input.MuiInput-input {
    color: white;
}