.container-uploads {
    display: flex;
    align-items: center;
    margin: 20px;
}

/* .section-bold-upload-files {
    display: block;
    height: 200px;
    width: 100%;
    background: #f0f2f5;
    border: 3px dashed #cacaca;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
} */
.section-bold-upload-files {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    width: 100%;
    background: #f0f2f5;
    border: 3px dashed #cacaca;
    border-radius: 10px;
    padding: 10px;
    overflow: auto;
}

.section-bold-upload-files img {
    width: 120px;
    max-height: 140px;
    margin: 0px 12px;
}

.inner-upload {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.inner-upload .bold-btn {
    border: 0px;
}

span.title-upload {
    font-size: 17px;
    font-weight: 200;
    margin-top: 10px;
    text-align: center;
    width: 100%;
}

.inner-upload span.material-symbols-outlined {
    background: #ff9c33;
    color: white;
    width: 55px;
    height: 55px;
    border: 0px;
    border-radius: 50%;
    text-align: center;
    line-height: 55px;
    font-size: 30px;
    box-shadow: 0px 0px 13px #ababab;
}

.buttons-options {
    display: flex;
}

.buttons-options button {
    height: 60px;
    background: transparent;
    border: 0;
    color: #808080;
    font-size: 11px;
}

.buttons-options button span {
    border: 1px dashed #C9CACC;
    border-radius: 5px;
    color: #808080;
    padding: 5px;
}
.files-text{
    font-size: 10px; /* Tamaño de letra pequeña */
    color: gray; /* Color del texto */
}