/* TextField.css */
.custom-text-field {
    position: relative;
    margin: 16px 0;
    display: flex;
    flex-direction: column;
  }
  
  .custom-text-field input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    transition: border-color 0.3s;
  }
  
  .custom-text-field input:focus {
    border-color: #005cc8;
  }
  
  .custom-text-field label {
    position: absolute;
    top: 0;
    left: 12px;
    padding: 0 4px;
    background: white;
    color: #757575;
    font-size: 16px;
    transition: 0.2s ease all;
    pointer-events: none;
  }
  
  .custom-text-field input:focus + label,
  .custom-text-field input:not(:placeholder-shown) + label {
    top: -14px;
    left: 8px;
    color: #3f51b5;
    font-size: 12px;
  }
  