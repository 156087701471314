/* selector con imagenes - select country */
.dark .container-bold-selector{
    position: absolute;
}
.dark .bold-selector {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    padding: 0px 0px 0px 2px;
    margin-top: 9px !important;
    border-bottom: 1px solid #e5e5e5;
}
.dark .bold-select-selected{
    width: 50px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}
.dark .bold-selector .bold-select-selected img{
    width: 30px;
}
.dark .bold-selector-options {
    display: none;
}
.dark .bold-selector-options.show {
    display: block !important;
    position: relative;
    width: 100%;
    z-index: 11;
    box-shadow: 3px 3px 4px -3px black;
    border-radius: 4px;
    overflow: hidden;
}
.dark .bold-selector-option{
    background: #FFFFFF;
    border: 1px solid #272424;
    border-radius: 5px;
    padding: 2px 10px;
    margin-top: 1px;
}
.dark .bold-selector-option:nth-child(1){
    border-top: none;
}
.dark .bold-selector-option img{
    width: 30px !important;
    margin-right: 10px;
}

.dark .bold-input-phone {
    padding-left: 60px !important;
}

.container-bold-input-phone .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
    padding-left: 80px;
}

/* Modo oscuro */
.dark
.bold-selector-options.show {
    box-shadow: 3px 3px 4px -3px black;
}
.dark .bold-selector-option {
    background: #404040;
    border-top: 1px solid #5c5c5c;
}