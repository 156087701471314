html {
    background: var(--light-primary-white-4);
}
body{
    font-family: 'Open Sans' !important;
}

.no-view {
    display: none !important;
}
.absolute{
    position: absolute;
    width: 50px;
}

.bg-login {
    height: 100vh;
    background: var(--light-translucent-black-3);
    /* background-image: url('/public/img/bg-sign-in.jpeg'); */
    background-image: url('/public/img/bg-login.jpg');
    background-size: cover;
    background-position: center;
}
img.logo-login {
    width: 240px;
    display: block;
    margin: 0px auto 30px;
}
img.logo-register {
    width: 270px;
    display: block;
    margin: 25px auto 30px;
}
.contenedor-imagen {
    height: 100vh;
    width: 50vw;
    background-image: url('/public/img/bg-login-tablets.jpg'); 
    background-size: cover; 
    background-position: center; 
}
.title-login{
    color: var(--light-primary-light-gray-2);
    font-size: 30px;
    margin-bottom: 0;
    font-weight: 400;
}
.button-information{
    color: var(--light-primary-white-1);
    background: linear-gradient(0deg, var(--light-primary-black-1), var(--light-translucent-black-4));
    border-radius: 100%;
}

.np-row{
    margin-right: 0px !important;
    margin-left: 0px !important;
}
.ph-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--light-secondary-dark-gray-4);
    width: 100%;
}

.section-login {
    background: var(--light-primary-white-1);
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: 0px 0px 16px var(--light-secondary-gray-3);
    max-width: 550px;
    margin: auto !important;
}

.subtitle-text-login {
    color : var(--light-secondary-dark-lavender);
}

.MuiTextField-root {
    width: 100%;
    margin-bottom: 15px !important;
}

.aside {
    
    background: linear-gradient(195deg, var(--light-secondary-gray-1), var(--light-primary-black-3));
    /* background: linear-gradient(195deg, rgb(70, 70, 78), rgb(232 33 33)); */
    max-width: 250px;
    width: 100%;
    display: block;
    float: left;
    overflow-y: auto;
    padding: 15px;
    min-height: calc(100vh - 30px);
    color: var(--light-color-aside-1);
    position: fixed;
    z-index: 1;
    margin: 15px;
    border-radius: 20px;
    transition: all 0.3s linear;
    box-shadow: var(--light-translucent-black-2) 0rem 0.25rem 0.375rem -0.0625rem, var(--light-translucent-black-1) 0rem 0.125rem 0.25rem -0.0625rem;
}
.bgAside-dark {
    height: 100%;
    display: flex;
    flex-direction: column;
}   

.aside.hide {
    left: -280px;
}
.aside.show {
    left: 0px;
    top: 0px;
    bottom: 0px;
}
ul#menu-list {
    overflow: auto;
    display: block;
    height: 83vh;
}
.shadow-aside {
    background: var(--light-translucent-gray-5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
}
.shadow-aside .menu-btn-toggle{
    display: block;
}
/* .menu-btn-toggle {
    display: none;
    position: absolute;
    right: 15px;
    top: 40px;
    color: var(--light-primary-white-1);
    background: var(--light-secondary-dark-gray-2);
    width: 36px;
    height: 36px;
    padding-top: 6px;
    padding-left: 4px;
    border-radius: 50%;
    text-shadow: 2px 1px 4px #b9b9b9;
} */
.menu-btn-toggle {
    display: none;
    position: absolute;
    right: 15px;
    top: 40px;
    font-size: 30px;
    color: var(--light-secondary-white-6);
    background: var(--light-secondary-dark-gray-2);
    width: 36px;
    font-weight: 200;
    height: 36px;
    line-height: 34px;
    text-align: center;
    border-radius: 50%;
    text-shadow: 2px 1px 4px var(--light-primary-gray-6);
}

span.MuiSkeleton-root {
    margin-bottom: 10px;
}

.aside ul {
    list-style: none;
    padding: 0px;
    overflow-y: auto;
}

.bold-container {
    display: block;
    float: left;
    margin-left: 280px;
    min-height: 96vh;
    padding: 15px 15px 5px;
    width: calc(100% - 280px);
    position: relative;
    transition: all .3s linear;
}
.bold-container.full {
    margin-left: 0px;
    width: 100%;
    transition: all .3s linear;
}

.loader {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--light-translucent-white-1);
    z-index: 1;
}

.bold-container.responsive {
    margin-left: 75px;
    width: calc(100% - 80px);
}

.bold-container-content {
    background: var(--light-primary-white-1);
    padding: 20px 10px;
    border-radius: 15px;
}

.bold-container-content2 {
    padding: 10px 10px;
    border-radius: 15px;
    color: var(--light-secondary-night-blue);
    font-size: 28px;
    font-weight: 600;
}

.container-result{
    width: 100%;
    height: 100%;
    background-color: var(--light-primary-white-4);
}

.container-result-transaction{
    width: auto;
    height: 100vh;
    min-width: 35vw;
    justify-content: center;
}

.check{
    width: 5rem;
    height: 5rem;
    background-color: var(--light-primary-black-2);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 3vh;

}

.download-button{
    border:none;
    color: var(--light-primary-red-1);
    
}

.result-transaction{
    width: 100%;
    padding: 2rem 3rem 2rem 3rem;
    box-shadow: 2px 2px 12px 0px var(--light-secondary-white-7);
}

.card-content {
    border-radius: 10px;
    border: 1px solid var(--light-translucent-gray-1);
    justify-content: space-between;
}
.border-selected-card{
    border-radius: 15px;
    justify-content: space-between;
    border: 1px solid var(--dark-secondary-coral-red);
}

.card-content-modal {
    border-radius: 15px;
    border: 1px solid var(--light-translucent-gray-1);
    background-color: var(--light-primary-white-1);
    justify-content: space-between;
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    inset: auto;
    margin: 0 auto;
    padding: 20px;
}

.logo {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--light-translucent-white-2);
}

/* ASIDE */
.brand-logo {
    width: 100%;
    display: block;
    z-index: 1;
}
li.menu-item>a {
    text-decoration: none;
    color: var(--light-secondary-white-5);
    font-weight: 100;
    line-height: 30px;
    padding: 4px 5px 4px 10px;
    border-radius: 10px;
    margin: 2px 0px;
    display: block;
}

li.menu-item>a:hover {
    background: var(--light-hover-menu);
}

li.menu-item span.material-symbols-outlined {
    line-height: 22px;
    font-size: 20px;
    position: relative;
    top: 3px;
    padding-right: 10px;
}

/* ASIDE ACCORDION */
li.menu-item.collapsable>a.collapsed:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 12px;
    border-bottom: 2px solid var(--light-primary-light-gray-1);
    border-right: 2px solid var(--light-primary-light-gray-1);
    transform: rotate(45deg);
}

li.menu-item.collapsable>a:after {
    content: "";
    width: 10px;
    height: 10px;
    position: absolute;
    right: 8px;
    top: 17px;
    border-bottom: 2px solid var(--light-primary-light-gray-1);
    border-right: 2px solid var(--light-primary-light-gray-1);
    transform: rotate(225deg);
}

li.menu-item.collapsable a {
    position: relative;
    width: 100%;
    display: block;
}


/* MENU */
.title-dashboard {
    padding-left: 6px;
    margin-right: 2vw;
}

/* INPUTS */
.input-icon {
    position: relative;
    width: 100%;
    margin-bottom: 0px;
    margin-left: 15px;
}
/* input search */
.input-icon.input-search .clear{
    color: var(--light-input-search-clear);
    right: 50px;
    font-size: 16px;
    top: 10px;
    cursor: pointer;
}

.input-icon input {
    flex-grow: 1;
    width: 100%;
    margin-right: 10px;
    border: 1px solid var(--light-secondary-white-4);
    border-radius: 8px;
    height: 36px;
    padding-right: 60px;
    padding-left: 10px;
    font-size: 14px;
}
.input-icon span {
    position: absolute;
    top: 6px;
    right: 20px;
    color: var(--light-primary-light-gray-4);
    text-shadow: 2px 1px 2px var(--light-secondary-white-2);
}


/* FOOTER */
.footer-branch {
    font-size: 15px;
    margin: 20px 15px 0px;
}

ul.important-links {
    display: flex;
    list-style: none;
    margin-bottom: 5px;
}

ul.important-links li {
    padding: 0px 12px;
}

ul.important-links a {
    text-decoration: none;
}

.company-branch-link {
    font-weight: 700;
    text-decoration: none;
    color: var(--light-secondary-midnight-blue);
}

.modal-sub-location {
    width: 40%;
    height: 60%;
    min-width: 350px;
    min-height: 550px;
    margin: auto;
    margin-top: 15vh;
    background-color: var(--light-primary-white-1);
    border: 1px solid var(--light-translucent-night-blue);
    border-radius: 30px;
    overlay: auto;

}

.plan-card{
    border-radius: 20px;
    border: 1px solid var(--light-translucent-night-blue);
    box-shadow: 0px 0px 15px var(--light-translucent-gray-4);

}

.plan-card-register{
    border-radius: 20px;
    border: 1px solid var(--light-translucent-night-blue);
    box-shadow: 0px 0px 15px var(--light-translucent-gray-4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25px 15px;
    width: 45%;
}
.check-plans{
    border-radius: 100%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.white{
    color: '#fffff';
}
.select-card-dark{
    background-color: #36366E;
    border: 2px solid #da3452;
    margin-top: 0;
    margin-bottom: 1rem;
}
.not-select-card-dark{
    background-color: #5a5f72;
    margin-top: 1rem;
}
.select-card-dark:hover{
    background-color: #393987;
    border: 2px solid #da3452;
}
.not-select-card-dark:hover{
    background-color: #656a7f;
}
.select-card{
    background-color: #202742;
    border: 2px solid #da3452;
    margin-top: 0;
    margin-bottom: 1rem;
    color: #ffffff;
}
.not-select-card{
    background-color: #ffffff;
    margin-top: 1rem;
    color: #202742;
}
.select-card:hover{
    background-color: #222b4f;
    border: 2px solid #da3452;
}
.select-card-dark:active{
    background-color: #36366E !important;
}
.select-card:active{
    background-color: #222b4f !important;
}
.not-select-card-dark:active{
    background-color: #5a5f72 !important;
    color: #202742;
}
.not-select-card:hover{
    background-color: #ffffff;
    color: #202742;
}
.not-select-card:active{
    background-color: #ffffff !important;
    color: #202742;
}
.selected-card{
    background-color: #ffffff;
    color: #36366E;
}
.not-selected-card{
    background-color: #202742;
    color: #ffffff;
}
/* TABLE */
.container-table header {
    display: none;
}
.bold-header-table {
    display: flex;
    justify-content: space-between;
}
.bold-header-table .section-events {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
img.bold-image-table {
    width: 60px;
    max-height: 60px;
    border-radius: 12px;
    margin: 8px 0px;
}
img.bold-image {
    width: 50px;
}
img.bold-image-diners {
    width: 60px;
    max-height: 40px;
    margin: 8px 0px;
    border-radius: 12px;

}
.section-events .input-icon {
    width: 45%;
    margin-right: 5px;
    display: flex;
    justify-content: flex-start;
}

.column-dt dt {
    font-weight: 700;
    color:var(--light-secondary-night-blue);
}
.column-dd dt {
    font-weight: 300;
    color:var(--light-secondary-night-blue);
}

.bold-header-table h3 {
    color: var(--light-secondary-gray-2);
    font-weight: 400;
    margin-left: 25px;
}

.action-buttons{
    display: flex;
    margin-right: 10px;
}
button.btn-table-actions {
    background: transparent;
    border: 0px;
    color: var(--light-secondary-midnight-blue);
    text-shadow: 2px 1px 2px var(--light-secondary-white-2);
}

button.btn-table-actions-massives {
    background: transparent;
    display: flex;
    border: 0px;
    border-radius: 5px;
    color: var(--light-primary-white-1);
    /* font-weight: bold; */
    height: 36px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

button.btn-table-actions-massives.inactive {
    background: #ff9b15;
}

button.btn-table-actions-massives.active {
    background: #08a215;
}

button.btn-table-actions-massives.delete {
    background: #d32f2f;
}

button.btn-table-actions-massives.edit {
    background: #0070FF;
}

button.btn-table-actions-massives.save {
    background: #0C7F5A;
}

button.btn-table-actions-massives.cancel {
    background: #CF3131;
}

button.btn-table-add {
    background: transparent;
    height: 30px;
    width: 30px;
    padding: 0;
    border: 2px solid var(--light-translucent-indigo-blue);
    border-radius: 10px;
    color: var(--light-translucent-indigo-blue);
    text-shadow: 2px 1px 2px var(--light-secondary-white-2);
}

button.btn-table-actions span {
    font-size: 20px;
}
.material-symbols-outlined.active{
    color: var(--light-status-active);
}
.material-symbols-outlined.inactive{
    color: var(--light-status-inactive);
}

.rdt_TableCol {
    color: var(--light-secondary-slate-gray);
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
}

.card-numbers-content{
    margin-left: 5px;
}

.card-numbers {
    color: var(--light-secondary-slate-gray);
    font-size: 12px;
    font-weight: 600;
}

.rdt_TableCell {
    color: var(--light-secondary-gray-3);
    font-weight: 300;
}

dt {
    font-weight: 600;
    color: var(--light-secondary-bluish-gray);
    font-size: 15px;
}
dd {
    margin-bottom: 0.5rem;
    margin-left: 0;
    font-weight: 200;
    color: var(--light-secondary-lavender);
    word-wrap: break-word;
}
.flex-column-container {
    flex-wrap: wrap;
}
/* BUTTOMS */
.btn-action-dash{
    border:0px;
    display: inline-block;
    padding: 4px;
    margin: 0px -1px;
    background: transparent;
}
button.dropdown-item.selected {
    font-weight: 700;
}
.bold-btn {
    border: 0px;
    padding: 6px 12px;
    font-size: 16px;
    line-height: 22px;
    vertical-align: middle;
    display: inline-block;
    background: transparent;
    margin: 5px 10px;
}

button.bold-btn-dark {
    color: var(--light-primary-white-1);
    background: linear-gradient(0deg, var(--light-secondary-charcoal-blue), var(--light-primary-black-2));
    border-radius: 8px;
}
button.bold-btn-dark.disabled {
    color: rgb(255, 255, 255);
    background: #919191;
    border-radius: 8px;
}
button.pagination-button {
    font-family: monospace;
    border-radius: 5px;
    border: 1px solid #344767;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

button.cancel-subscription-button {
    border: none;
    background-color: var(--light-primary-white-1);
    color: var(--light-translucent-gray-3);
    text-decoration: underline;
}
.label-default{
    font-size: 13px;
    font-weight: 600;
    background-color: var(--light-secondary-charcoal-blue);
    color: var(--light-primary-white-1);
    width: 8rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.label-default-dark{
    font-size: 13px;
    font-weight: 600;
    background-color: #f4f4f5;
    color: var(--light-primary-black-1);
    width: 8rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.default-card-button{
    border-radius: 5px;
    border: 1px solid var(--light-secondary-night-blue);
    width: 8rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.default-card-button:hover{
    border-radius: 5px;
    border: 1px solid var(--light-secondary-night-blue);
    background-color: #dbdbdb;
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.default-card-button-dark{
    border-radius: 5px;
    border: 1px solid #f4f4f5;
    width: 8rem;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}
.default-card-button-dark:hover{
    border-radius: 5px;
    border: 1px solid #f4f4f5;
    background-color: #555555;
    width: 8rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.color-text-default-dark{
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: var(--light-primary-white-1);
}
.color-text-default{
    font-size: 13px;
    font-weight: 600;
    margin: 0;
    padding: 0;
    color: var(--light-primary-gray-6);
}

button.btn-gray {
    color: var(--light-primary-white-1);
    background: linear-gradient(0deg, var(--light-secondary-dark-gray-5), var(--light-secondary-gray-5));
    border-radius: 8px;
}

button.btn-gray-light {
    color: var(--light-primary-white-1);
    background: linear-gradient(0deg, var(--light-translucent-light-gray-1), var(--light-secondary-light-gray-2));
    border-radius: 8px;
}

button.bold-btn-dark-big {
    color: var(--light-primary-white-1);
    background: linear-gradient(0deg, var(--light-primary-black-1), var(--light-translucent-black-4));
    border-radius: 8px;
    width: 75%;
}

button.bold-btn-light {
    color: var(--light-primary-gray-2);
    background: linear-gradient(195deg, var(--light-light-blue-gray-1), var(--light-light-blue-gray-2));
    border-radius: 8px;
}

.filters-button >button {
    font-size: 12px;
    max-height: 30px;
    margin: 14px 5px;
}

.overflow-text {
    overflow: hidden; 
    text-overflow: ellipsis;
}

.align-end {
    display: flex;
    justify-content: flex-end;
}

.red-dot {
    width: 10px;
    height: 10px;
    background-color: var(--light-primary-red-2);
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px; /* Ajusta el espacio entre el círculo y el texto del label según sea necesario */
}
.green-dot {
    width: 10px;
    height: 10px;
    background-color: var(--light-primary-green-2);
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px; /* Ajusta el espacio entre el círculo y el texto del label según sea necesario */
}
.yellow-dot {
    width: 10px;
    height: 10px;
    background-color: var(--light-primary-yellow-1);
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px; /* Ajusta el espacio entre el círculo y el texto del label según sea necesario */
}

/* Forms */

.section-image-view {
    text-align: center;
    width: 130px;
    max-width: 150px;
    max-height: 150px;
    min-height: 125px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
}

.circle {
    border-radius: 70%;
}

.margin-cero-padding-cero {
    margin: 0;
    padding: 0;
}

.bold-container-content > .row {
    max-width: 960px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.section-forms {
    background: var(--light-primary-white-1);
    padding: 30px 20px;
    border-radius: 15px;
    margin: 10px;
    box-shadow: var(--light-translucent-black-2) 0rem 0.25rem 0.375rem -0.0625rem, var(--light-translucent-black-1) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: auto;
    scrollbar-width: none;
}

.close-card {
    max-height: 60vh;
    min-height: 60vh;

}

.title-section-forms {
    width: 90%;
    display: block;
    text-align: left;
    color: var(--light-secondary-night-blue);
    font-size: 28px;
    font-weight: 600;
    margin-left: 30px;
}

.title-section-forms p{
    color: var(--dark-secondary-night-blue);
    font-size: 13px;
    font-weight: 100;
}

.title-section-products-forms {
    width: 90%;
    display: block;
    text-align: left;
    color: var(--light-secondary-night-blue);
    font-size: 28px;
    font-weight: 600;
    margin-left: 30px;
}

.title-section-products-forms p{
    color: var(--dark-secondary-night-blue);
    font-size: 13px;
    font-weight: 100;
}

.title-section-sub {
    display: block;
    color: var(--light-secondary-night-blue);
    font-size: 28px;
    font-weight: 600;
    margin: 0px 0px 40px;
}
.title-section {
    display: block;
    color: var(--light-secondary-night-blue);
    font-size: 28px;
    font-weight: 600;
}

.form-title {
    font-size: 20px;
    margin: 30px 0px 8px;
    color: var(--light-secondary-night-blue);
    font-weight: 400;
    padding: 0px 20px;
}
.form-title-sub {
    font-size: 13px;
    color: var(--light-secondary-night-blue);
    font-weight: 400;
    text-align: center;
}
.description-input{
    color: #7b809a;
    font-size: 12px; 
    margin-top: -1.5vh;
    margin-bottom: 1.5vh;
}
span.divisor {
    width: 80%;
    margin: 10px auto;
    display: block;
    height: 1px;
    background: var(--light-secondary-white-1);
}

.orders-to-close {
    height: 80%;
    overflow: scroll;
}

.section-bottom-card-close {
    display: flex;
    border-top: 1px solid var(--light-translucent-gray-2);
    height: 5vh;
    justify-content: space-evenly;
}

.green-text {
    color: var(--light-secondary-light-green-2);
    font-weight: 700;
    font-size: 20px;
}

.total-text {
    color: var(--light-secondary-night-blue);;
    font-weight: 600;
    font-size: 18px;
}

.text-order {
    font-weight: 400;
    font-size: 16px;
    color: var(--light-translucent-gray-3);
}
/* radio group */
.MuiFormGroup-root {
    display: block !important;
    padding: 10px 15px;
}
.MuiFormGroup-root label {
    padding-right: 15px;
}
.MuiFormGroup-root label span:nth-child(2) {
    font-size: 15px;
    font-weight: 300;
}

/* checkbox */
label.MuiFormControlLabel-root span:nth-child(2) {
    font-weight: 300;
    color: var(--light-primary-gray-3);
}


/* Text */
.regular {
    font-weight: 600;
}
.bold {
    font-weight: 800;
}
.semi-light {
    font-weight: 400;
}
.light {
    font-weight: 300;
}
.small-text{
    font-size: 13px;
}

.medium-text{
    font-size: 14px;
}
.big-text{
    font-size: 20px;
}

.text-green {
    color: var(--light-primary-green-1);
}

.min-height{
    min-height: 48px;
}

.text-orange {
    color: var(--light-primary-orange-1);
}

.text-link {
    color: var(--light-primary-red-1);
    text-decoration: none;
    cursor: pointer;
}

.text-linkSub {
    text-decoration: underline;
    color: var(--light-primary-red-1);
}

.text-red {
    color: var(--light-secondary-dark-red-2);
}

.text-gray {
    color: var(--light-secondary-light-gray-4) !important;
}
.bold-text-field-material textarea{
    font-size: 13px !important;
}


/* Widgets */
.widget {
    background: var(--light-primary-white-1);
    border-radius: 20px;
    padding: 10px 15px 20px;
    margin: 0px 15px 40px;
    position: relative;

}

.widget hr {
    clear: both;
    color: var(--light-primary-light-gray-3);
}

.icon-widget-1 {
    background: linear-gradient(0deg, var(--light-secondary-marine-blue), var(--light-primary-blue-1));
}

.icon-widget-2 {
    background: linear-gradient(0deg, var(--light-secondary-dark-green-1), var(--light-secondary-light-green-1));
}

.icon-widget-3 {
    background: linear-gradient(0deg, var(--light-secondary-dark-red-1), var(--light-primary-fucsia-1));
}

.icon-widget-4 {
    background: linear-gradient(0deg, var(--light-secondary-violet), var(--light-secondary-soft-violet));
}

.icon-widget {
    width: 60px;
    height: 60px;
    text-align: center;
    position: absolute;
    top: -16px;
    left: 25px;
    border-radius: 8px;
}

.icon-widget span {
    color: var(--light-primary-white-1);
    width: 100%;
    text-align: center;
    line-height: 60px;
    font-size: 30px;
}

.title-widget {
    display: block;
    float: right;
    font-weight: 200;
    color: var(--light-secondary-light-gray-1);
    padding: 0px 10px 10px;
}

.title-widget-complement {
    color: var(--light-primary-black-1);
    font-weight: 600;
}

.compare-widget {
    font-weight: 300;
}


/* MUI library */
/* borde inferior inputs */
.css-1dbuez4-MuiInputBase-root-MuiInput-root-MuiSelect-root:before,
.css-whebh7-MuiInputBase-root-MuiInput-root:before,
.css-1np443e-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid var(--light-translucent-light-gray-2) !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: var(--light-primary-blue-3) !important;
    font-weight: 500 !important;
}

.MuiPaper-root {
    border-radius: 10px !important;
    margin-top: 2px;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    margin: 0px 6px !important;
    border-radius: 7px !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
    background-color: var(--light-primary-white-5) !important;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
    border-radius: 7px;
    margin: 0px 6px;
    background-color: var(--light-translucent-blue) !important;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl {
    color: var(--light-secondary-slate-gray);
    font-weight: 200;
    max-width: 85%;
    /* z-index: auto; */
}

.MuiFormControl-root.MuiTextField-root label {
    font-weight: 200;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    z-index: 0;
}

.css-15vnl53-MuiFormControl-root{
    z-index: 0;
}

/*USERS FORM*/
.circle-name {
    background: var(--light-secondary-dark-gray-3);
    color: var(--light-primary-white-1);
    text-align: center;
    height: 3em;
    width: 3em;
    border-radius: 50%;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    position: relative;
    overflow: hidden;
}
img.profile-image {
    width: 100%;
}
.bold-button-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: var(--light-primary-white-1);
    color: var(--light-secondary-dark-gray-3);
    border-radius: 100px;
    height: 30px;
    width: 30px;
    position: absolute;
    bottom: 15px;
    right: 10px;
    border:none;
}
a.bold-button-modal:hover {
    color: var(--light-secondary-gray-4);
}
.form-username {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.form-username label {
    margin: 20px 0;
    color: var(--light-secondary-gray-4);
}
.user-account span {
    padding-left: 7px;
}
.user-account {
    padding-top: 0;
    font-size: 13px;
    vertical-align: bottom;
}
.breadcrumb-item a {
    color: var(--light-secondary-dark-gray-1); /* Cambiar el color del texto a tu preferencia */
    text-decoration: none; /* Eliminar el subrayado */
}

.breadcrumb-item a:hover {
    color: var(--light-primary-gray-1); /* Cambiar el color del texto al pasar el mouse por encima */
}


li.menu-children {
    font-size: 14px;
    padding-left: 15px;
}

.no-imformation-opacity{
    color: var(--light-translucent-light-lavender);
}

/* infinite add items */
span.material-symbols-outlined.icon-delete {
    padding-top: 15px;
}

.register-subtitle-1 {
    color: var(--light-secondary-gray-5);
    font-weight: bold;
}

.register-subtitle-2 {
    color: var(--light-secondary-gray-5);
    font-size: 14px;
}

.register-column-6 {
    flex: 0 0 auto;
    width: 50%;
}
.register-column-4 {
    flex: 0 0 auto;
    width: 33.33333%;
}
.register-column-3 {
    flex: 0 0 auto;
    width: 25%;
}

.simple-text {
    color: var(--light-primary-black-1);
}

.swal2-styled.swal2-confirm {
    background-color: var(--light-secondary-purple);
    color: var(--light-primary-white-1);
}

.swal2-styled.swal2-cancel {
    background-color: var(--light-primary-gray-5);
}


.text-details {
    padding-right: 15px;
    font-weight: 400;
    font-size: 14px;
    color: var(--light-translucent-gray-3);
}

.no-data-display {
    text-align: center;
    padding: 20px;
}
.back-arrow {
    margin-left: -15px;
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Permite un desplazamiento suave en dispositivos táctiles */
}

.table thead th {
    white-space: nowrap; /* Evita que el texto se desborde de las celdas */
}

.table tbody td {
    white-space: nowrap;
}

.mass-action-buttons {
    display: flex;
    gap: 10px;
    margin-left: 15px;
}

.swal2-popup {
    border-radius: 15px;
}

/* Botón cierre de caja */
.container-close-cashier--button {
    width: 90%;
    display: block;
    text-align: center;
    color: var(--light-secondary-night-blue);
    font-size: 28px;
    font-weight: 600;
    margin-left: 30px;
}

.register-header-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 5px 10px;
    align-items: center;
}

.button-go-login a {
    font-size: 0.8rem;
}
.button-go-login a:hover {
    color: #e2151b !important;
    border-bottom: 1px solid #e2151b;
}

.dark-mode-toggle button {
    display: flex;
    border: 1px solid #d3d3d3;
    background-color: transparent;
    border-radius: 5px;
    padding: 2px 5px;
    align-items: center;
    gap: 5px;
    width: 95px;
}

.dark-mode-toggle p {
    font-size: 13px;
    color: var(--dark-primary-white-3);
    margin: 0;
}

.button-togle-theme {
    align-self: flex-end;
}

.registerContainerForm .dark-mode-toggle span,
.registerContainerForm .dark-mode-toggle p {
    color: var(--light-secondary-light-gray-4);
}

.row-card-numbers{
    padding-right: 2px;
}
/* Responsive */
@media(max-width:640px) {
    .bg-login {
        background-image: url('/public/img/bg-login-mobile.jpg');
    }
    .register-column-6 {
        flex: 0 0 auto;
        width: 100%;
    }
    .register-column-4 {
        flex: 0 0 auto;
        width: 33.33333%;
    }
    .register-column-3 {
        flex: 0 0 auto;
        width: 50%;
    }
}

@media(max-width:790px) {
    .aside.hide {
        left: -265px;
        transition: all 0.3s linear;
    }

    .bold-container {
        width: 100%;
        margin-left: 0px;
        transition: all 0.3s linear;
    }

    .section-events .input-icon {
        width: 55%;
        margin-right: 10px;
    }
    
}

@media (max-width: 768px) {
    .footer-branch {
        flex-direction: column;
    }

    ul.important-links {
        margin-top: 10px;
        justify-content: start;
        padding: 0;
    }
    ul.important-links li{
        padding: 0;
        justify-content: start;
    }

    .close-card {
        max-height: 60vh;
        min-height: 60vh;
        overflow: auto;
        scrollbar-width: none;
        width: 90%;
    }

    .cmodal{
        display: flex;
        overflow: auto;
    }

    .marginCard {
        margin-top: 20px;
    }

    .register{
        display: flex;
        flex-direction: column;
    }

    .registerImagen {
        width: 100%;
        height: 100vh;
    }

    .registerContainerForm {
        width: 100% !important;
        position: absolute;
        background-image: url('/public/img/bg-login.jpg');
        background-size: cover; /* La imagen cubre todo el fondo */
        background-position: center; /* Centra la imagen */
        display: flex;
        justify-content: center; /* Centra horizontalmente el contenido */
        align-items: center; /* Centra verticalmente el contenido */
        overflow: hidden; /* Oculta el scroll del contenedor */
    }

    .registerForm {
        margin: 20px auto;
        background-color: var(--light-primary-white-1);
        width: 80% !important;
        justify-content: center !important;
        overflow: hidden;
        border-radius: 10px;
        overflow-y: auto; /* Permite el desplazamiento vertical si el contenido es demasiado alto */
        padding: 20px; /* Ajusta el relleno según sea necesario */
        box-sizing: border-box; /* Incluye el padding en el tamaño total del elemento */
    }

    .logo-r {
        width: 225px !important;
    }
    .registerContainerForm .button-go-login a{
        color: var(--light-primary-light-gray-1);
    }
    .registerContainerForm .dark-mode-toggle {
        top: 0;
        right: 0;
    }
    .registerContainerForm .dark-mode-toggle span{
        color: var(--light-primary-gray-1);
    }
    .registerContainerForm .dark-mode-toggle p{
        color: var(--light-primary-gray-1);
    }
    
}

@media (max-width: 468px) {

    .hidden{
        display: none;
    }
    .section-events .input-icon {
        width: 70%;
        margin-right: 10px;
    }
  }

  @media(max-width:480px) {
    .bold-header-table {
        display: block !important;
    }
    .bold-header-table .section-events {
        width: 100% !important;
    }

    .bold-header-table .section-events {
        justify-content: inherit !important;
    }

    .container-uploads {
        display: block !important;
    }

    .d-flex-t {
        display: block !important;
    }

    .registerContainerForm .dark-mode-toggle span{
        font-size: 30px;
    }
}

@media(max-width:420px) {
    .bold-infinite-title-section h3 {
        font-size: 18px;
        font-weight: 200;
        margin-top: 10px;
        margin-left: -15px;;
    }
    button.bold-infinite-option {
        width: 70px;
    }
    .logo-l {
        width: 200px !important;
    }
    span.title-upload {
        font-size: 14px;
    }
}

@media(max-width:470px) {
    .title-section-forms {
        font-size: 19px;
        width: 90%;
        margin-left: 20px !important;
    }
    .title-section-products-forms {
        font-size: 19px;
        width: 70%;
        margin-left: 40px !important;
    }
    .logo-r {
        width: 180px !important;
        /* padding-top: 200px !important; */
    }
}
@media(max-width:360px) {
    .logo-l {
        width: 160px !important;
    }
    .logo-r {
        width: 150px !important;
        /* padding-top: 200px !important; */
    }
}

@media (max-width: 918px) {

    .col-lgs{
        width: 100%;
    }
}


@media (max-width: 1100px) {

    .col-lgx-7{
        width: 90%;
    }

    .cardAccount{
        display: flex;
    }

  }

@media (min-width: 1250px) {
    .col-lg-card{
        width: 30%;
    }

}

@media (max-width: 1100px) {

    .col-lgx-7{
        width: 90%;
    }
    .back-arrow {
        margin-left: 0px;
    }

}

@media (max-width: 910px) {
    .mass-action-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-left: 15px;
        margin-right: 10px;
        justify-content: flex-start;
    }

    .logo-r{
        width: 200px !important;
    }

}
@media (max-width: 560px) {
    .register-column-4{
        width: 100%;
    }
    .toggle-dark-mode {
        display: flex;
        align-self: center;
        margin: 0;
        bottom: 10px;
    }
    
}

    .order{
        height: 10vh;
        display: flex;
        align-items: center;
    }

    
    .close-history-button{
        display: flex;
        justify-content: flex-end;
        margin-right: 35px;
    }

    .tittle-position{
        color: var(--light-primary-black-1);
        /* color: var(--light-secondary-night-blue); */
        font-size: 25px;
        font-weight: 500px;
    }

    .ml-2{
        margin-left: 40px;
    }

    .ml-1{
        margin-left: 25px;
    }

    .text-details {
        padding-right: 15px;
        font-weight: 400;
        font-size: 14px;
        color: var(--light-translucent-gray-3);
    }
    
    .no-data-display {
        width: 100%;
        text-align: center;
        padding: 20px;
    }
    
    .no-data-display-text {
        font-size: 15px; /* Ajusta el tamaño según tus necesidades */
    }
    
    .underline {
        text-decoration: underline;
        color: black; /* Puedes cambiar el color según tus preferencias */
    }

.MuiOutlinedInput-notchedOutline{
        border-color: #0000008a;
    }
    
.MuiOutlinedInput-notchedOutline:hover{
        border-color: #000000;
    }

    .MuiSvgIcon-root{
        color: #0000008a;
    }

    /*Checkbox para tablas*/
.container-table .Mui-checked.MuiButtonBase-root.MuiCheckbox-colorPrimary > .MuiSvgIcon-root{
    color: var(--light-primary-blue-4);
    font-size: 1.2rem;
}
.container-table .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    font-size: 1.2rem;
}

form  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    font-size: 1.5rem;
}

.MuiStep-root .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
    font-size: 1.5rem;
}

.dark [type=button]:not(:disabled),
.dark [type=reset]:not(:disabled),
.dark [type=submit]:not(:disabled),
.dark button:not(:disabled) {
    color: var(--dark-primary-white-2);    
}
/* Color de botones de desplazamiento y años no disponibles */
.dark [type=button]:disabled,
.dark [type=reset]:disabled,
.dark [type=submit]:disabled,
.dark button:disabled {
    color: var(--dark-secondary-light-gray-3);
}