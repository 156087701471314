/* selector con imagenes - select country */
.container-bold-input-phone{
    position: relative;
    width: 100%;
}

.container-bold-input-phone .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl{
    padding-left: 22%;
}
.container-bold-selector{
    position: absolute;
}
.bold-selector {
    display: block;
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    padding: 0px 0px 0px 2px;
    margin-top: 8px !important;
    border-bottom: 1px solid #e5e5e5;
}
.bold-select-selected{
    width: 50px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
}
.bold-selector .bold-select-selected img{
    width: 30px;
}
.bold-selector-options {
    display: none;
}
.bold-selector-options.show {
    display: block !important;
    position: relative;
    width: 100%;
    z-index: 11;
    box-shadow: 3px 3px 4px -3px black;
    border-radius: 4px;
    overflow: hidden;
}
.bold-selector-option{
    background: #FFFFFF;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 2px 10px;
    margin-top: 1px;
}
.bold-selector-option:nth-child(1){
    border-top: none;
}
.bold-selector-option img{
    width: 30px !important;
    margin-right: 10px;
}

.bold-input-phone {
    padding-left: 17% !important;
}

/* Modo oscuro */
.dark
.bold-selector-options.show {
    box-shadow: 3px 3px 4px -3px black;
}
.dark .bold-selector-option {
    background: #404040;
    border-top: 1px solid #5c5c5c;
}
@media(max-width: 1280px){
    .bold-input-phone {
        padding-left: 25% !important;
    }
}
@media(max-width: 1040px){
    .bold-input-phone {
        padding-left: 30% !important;
    }
}
@media(max-width: 767px){
    .bold-input-phone {
        padding-left: 14% !important;
    }
}
@media(max-width: 540px){
    .bold-input-phone {
        padding-left: 15% !important;
    }
    .bold-select-selected {
        width: 38px;
    }
    .bold-selector .bold-select-selected img {
        width: 22px;
        height: 20px;
        margin-top: 5px;
    }
    .bold-selector {
        margin-top: 9px !important;
    }
}