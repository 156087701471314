:root {
  
  /* Light colors */
--light-primary-white-1: #ffffff;
--light-primary-white-2: #f2f2f2;
--light-primary-white-3: #ededed;
--light-primary-white-4: #f0f2f5;
--light-primary-white-5: #f0f4f9;
--light-color-aside-1: #dddddd;
--light-secondary-white-1: #e7e7e7;
--light-secondary-white-2: #e0dddd;
--light-secondary-white-3: #dbdbdb;
--light-secondary-white-4: #cccccc;
--light-secondary-white-5: #cdcdcd;
--light-secondary-white-6: #c7c7c7;
--light-secondary-white-7: #c4c4c4;
--light-light-blue-gray-1: #ebeff4;
--light-light-blue-gray-2: #ced4da;
--light-primary-light-gray-1: #bababa;
--light-primary-light-gray-2: #bebcbc;
--light-primary-light-gray-3: #aeaeae;
--light-primary-light-gray-4: #a5a5a5;
--light-secondary-light-gray-1: #9a9a9a;
--light-secondary-light-gray-2: #aaaaaa;
--light-secondary-light-gray-3: #a1a1a1;
--light-secondary-light-gray-4: #9f9f9f;
--light-primary-gray-1: #555;
--light-primary-gray-2: #514f4f;
--light-primary-gray-3: #636363;
--light-primary-gray-4: #636262;
--light-primary-gray-5: #636c74;
--light-primary-gray-6: #838383;
--light-secondary-gray-1: #46464e;
--light-secondary-gray-2: #595959;
--light-secondary-gray-3: #5b5b5b;
--light-secondary-gray-4: #61616a;
--light-secondary-gray-5: #646464;
--light-hover-menu: #565656;
--light-secondary-dark-gray-1: #333;
--light-secondary-dark-gray-2: #282828;
--light-secondary-dark-gray-3: #2b2b2f;
--light-secondary-dark-gray-4: #5a5a5a4d;
--light-secondary-dark-gray-5: #434343; 
--light-secondary-bluish-gray: #535566;
--light-primary-black-1: #000000;
--light-primary-black-2: #181D32;
--light-primary-black-3: #191919;
--light-primary-green-1: #05ab0b;
--light-primary-green-2: #0bb402;
--light-secondary-light-green-1: #28e37d;
--light-secondary-light-green-2: #49B754;
--light-secondary-dark-green-1: #0e7512;
--light-secondary-slate-gray: #7b809a;
--light-secondary-lavender: #9a98b3;
--light-secondary-dark-lavender: #5a6a91;
--light-primary-blue-1: #2286f4;
--light-primary-blue-2: #1876d2;
--light-primary-blue-3: #1870c8;
--light-primary-blue-4: #3085d6;
--light-secondary-marine-blue: #104279;
--light-secondary-night-blue: #344767;
--light-secondary-violet: #4949ab;
--light-secondary-purple: #655cc9;
--light-secondary-soft-violet: #7474de;
--light-secondary-midnight-blue: #272751;
--light-secondary-charcoal-blue: #202742;
--light-primary-yellow-1: #fbff1d;
--light-primary-orange-1: #db8126;
--light-primary-red-1: #ff0000;
--light-primary-red-2: #f20303;
--light-primary-red-3: #ef0000;
--light-primary-red-4: #d33;
--light-secondary-red-1: #e20d13;
--light-secondary-dark-red-1: #a00606;
--light-secondary-dark-red-2: #c00707;
--light-primary-fucsia-1: #ff245a;
--light-status-active: #08a215;
--light-status-inactive: #ff9b15;
--light-input-search-clear: #df0606;
--light-translucent-white-1: #dedede96;
--light-translucent-white-2: #ffffff1b;
--light-translucent-black-1: #0000000f;
--light-translucent-black-2: #0000001a;
--light-translucent-black-3: #000000A0;
--light-translucent-black-4: #000000c4;
--light-translucent-light-gray-1: #787878e8;
--light-translucent-light-gray-2: #c1c0c06b;
--light-translucent-gray-1: #64646446;
--light-translucent-gray-2: #64646474;
--light-translucent-gray-3: #646464b7;
--light-translucent-gray-4: #5b5b5ba4;
--light-translucent-gray-5: #76767670;
--light-translucent-indigo-blue: #272751bd;
--light-translucent-night-blue: #27275175;
--light-translucent-fog-gray: #c4c5c95f;
--light-translucent-blue: #1d82e645;
--light-translucent-light-lavender: #9a98b44d;

  /* Dark colors */
--dark-primary-white-1: #ffffff;
--dark-primary-white-2: #f9f9f9;
--dark-primary-white-3: #eaeaea; 
--dark-primary-white-4: #d6d6d6; 
--dark-primary-white-5: #d2d2d2;  
--dark-primary-white-6: #c6c7c7; 
--dark-secondary-white-1: #fcf5f5;
--dark-secondary-white-2: #c7c7c7; 
--dark-secondary-white-3: #c6c6c6;
--dark-secondary-white-4: #bababa; 
--dark-secondary-white-5: #b7b7b7; 
--dark-secondary-shadow-gray: #c4c4c4; 
--dark-secondary-grey-silver: #adadad; 
--dark-secondary-white-pearl: #dcd7d7;
--dark-primary-black-1: #000000;
--dark-primary-black-2: #222222;
--dark-primary-black-3: #282828;
--dark-primary-black-4: #181D32;
--dark-primary-black-5: #2B2D35;
--dark-secondary-dark-gray-1: #262933;
--dark-secondary-dark-gray-2: #303139;
--dark-secondary-dark-gray-3: #20222a; 
--dark-secondary-dark-gray-4: #323131; 
--dark-secondary-dark-gray-5: #2b2b2f; 
--dark-secondary-dark-gray-6: #32343C; 
--dark-primary-gray-1: #676767;
--dark-primary-gray-2: #636262; 
--dark-primary-gray-7: #565656; 
--dark-primary-gray-4: #4f5158; 
--dark-primary-gray-5: #474747; 
--dark-primary-gray-6: #5b5b5b; 
--dark-secondary-light-gray-1: #aeaeae;
--dark-secondary-light-gray-2: #9f9f9f; 
--dark-secondary-light-gray-3: #919192; 
--dark-secondary-light-gray-4: #87888c; 
--dark-color-breadcrum-1: #333;
--dark-color-breadcrum-2: #555; 
--dark-secondary-steel-blue: #4e5873;
--dark-secondary-gray-blue: #6e84aa;
--dark-main-background: #14161f;
--dark-bg-main-widget: #20222a;
--dark-color-linked-visited: #997404;
--dark-primary-lavender-1: #d4d9ff;
--dark-primary-purple-1: #36366E;
--dark-primary-purple-2: #32326c;
--dark-secondary-purple-night: #272751;
--dark-secondary-pearl-purple: #a2a1b5;
--dark-primary-red-1: #ff0000; 
--dark-primary-red-2: #c00707;
--dark-primary-red-3: #f20303;
--dark-secondary-focus-red:#e90000;
--dark-secondary-candy-red: #e63232;
--dark-secondary-alert-red: #db2e2e;
--dark-secondary-coral-red: #da3452;
--dark-primary-fucsia-1: #E20DD9;
--dark-primary-fucsia-2: #b646ca;
--dark-primary-fluorescent-red: #f02e2e;
--dark-secondary-fluorescent-blue: #0DC8E2;
--dark-primary-blue-1: #268bd2;
--dark-primary-blue-2: #597fb8;
--dark-primary-blue-3: #1870c8;
--dark-primary-blue-4: #378add;
--dark-secondary-blue-steel: #344767;
--dark-primary-orange-1: #db8126;
--dark-primary-orange-2: #ff9b15;
--dark-secondary-light-yellow: #DEE20D;
--dark-secondary-fluorescent-yellow: #fbff1d;
--dark-secondary-soft-yellow: #ffe38b;
--dark-primary-green-1: #008000;
--dark-primary-green-2: #49B754;
--dark-primary-green-3: #0bb402;
--dark-primary-green-4: #05ab0b;
--dark-primary-green-5: #08a215;
--dark-secondary-fluorescent-green: #0DE262;
--dark-color-icon-success: #a5dc86;
--dark-color-circle-success: #a5dc864d; 
--dark-color-error :#d32f2f;
--dark-color-icon-error: #E20D1394;
--dark-color-border-inputs: #bdbdbd;
--dark-translucent-black-1: #0000000f;
--dark-translucent-black-2: #0000001a;
--dark-translucent-black-3: #ffffff1b;
--dark-translucent-gray-1: #76767670;
--dark-translucent-gray-2: #64646446;
--dark-translucent-gray-3: #76767635;
--dark-bg-login-1: #5a5a5a4d;
--dark-shadow-plan: #5b5b5ba4;
--dark-bg-button-3: #787878e8; 
--dark-shadow-section-1: #9e9e9eba; 
--dark-bg-loder-1: #dedede96;
--dark-translucent-white-1: #ffffff8a;
--dark-translucent-white-2 :#c1c1c18d;
--dark-bg-hover-select: #e9e9f042;
--dark-translucent-lavender-1: #a6a3c77b;
--dark-translucent-purple-1: #32326c9c;
--dark-translucent-purple-2: #2424bb4e;
--dark-translucent-blue-1: #32326c56;
--dark-translucent-blue-2: #27275175;
--dark-translucent-red-1: #da3453a0;
--dark-translucent-red-2: #7f1f316d;
--dark-translucent-light-blue: #90caf936;
}